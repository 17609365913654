<template>
    <div class="user-list">
        <app-list :opt="opt" @get="onGet"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: "参数设置",
                    columns: [{
                            label: "ID",
                            key: "id",
                            width: 120,

                        },
                        {
                            label: "Key",
                            key: "configKey"
                        },
                        {
                            label: "标题",
                            key: "configName"
                        },
                        {
                            label: "值",
                            key: "configValue"
                        },
                        {
                            label: "参数类别",
                            key: "configType"
                        },
                        {
                            label: "备注",
                            key: "remark"
                        },
                        {
                            label: "状态",
                            key: "configStateStr",
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "查看",
                                    on(row) {
                                        _this.$router.push("/main/parameter/detail/show/" + row.id)
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: [{
                            type: 0,
                            on() {
                                _this.$router.push("/main/parameter/detail/add/0");
                            }
                        },
                        {
                            type: 1,
                            on(data) {
                                _this.$router.push(
                                    "/main/parameter/detail/edit/" + data.data.id
                                );
                            }
                        },
                        {
                            type: 0,
                            icon:'question',
                            name: '白名单管理',
                            on() {
                                _this.$router.push("/main/whiteList/list");
                            }
                        },
                        // {
                        //   type: 2,
                        //   on(data) {
                        //     var dto = {
                        //       list: data.ids
                        //     };
                        //     _this.post("landlord-service/sysConfig/deleteByIds", dto).then(function() {
                        //       _this.$message({
                        //         showClose: true,
                        //         message: "删除成功!",
                        //         type: "success"
                        //       });
                        //       data.refresh();
                        //     });
                        //   }
                        // }
                    ]
                }
            };
        },
        created() {

        },
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                this.post("landlord-service/sysConfig/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    if (res.data && res.data.code == 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            let item = res.data.data[i];
                            item.configStateStr = item.configState == 1 ? '启用' : '禁用';
                        }
                    }
                    opt.cb(res.data);
                });
            }
        }
    };
</script>